#cadastro-sucesso {

    background: url('../img/site/bg_.jpg') center/cover no-repeat;
    color: #fff;
    height: 100%;
    padding-top: 80px;

    a {

      font-size: 14px;
      color: #0d61a8;
    }

    .remove-helper-block {

      .help-block {

        display: none !important;

      }

      &.has-error {

        label {

          color: $red !important;

        }

      }

    }

    .choose_ {

      label {

        color: #fff !important;

      }

    }

    .radio:checked + label:after{

      top: 11px;

    }

    h1 {
        color: #3da0f4;
        font-size: 25px;
        font-family: $light;
        margin: 60px 0 10px;
        padding: 0 !important;

        small {
            display: block;
            color: #fff;
            margin-top: 10px;
            font-size: 15px;
            font-weight: 800;
        }
    }

    p {
        color: #fff;
        margin: 0px 0 20px 0;

        &.helper-block {

            margin: 0px;
            float: left;
            padding: 6px 0 10px;
            width: 100%;
            color: #F24848;
            font-size: 14px;
        }
    }

    fieldset {
        border: 1pt solid rgba(255, 255, 255, 0.46);

        legend {
            padding: 0 40px;
        }

        h4 {
            font-weight: 500;
        }

        .subtitle {
            font-size: 16px;
        }
    }

    form {
        label {
            color: #fff;
        }
    }

    footer {
      display: none;
    }
}



.login-form {

  h1 {

    margin-bottom: 20px;

  }

}
