  .follow-up-wrapper {
    max-width: 98%;
    width: 100%;
    display: inline-block;
    float: left;

    .follow-up-box {
      border: 1px solid #e7e7e7;
      border-radius: 5px;
      background-color: white;
      margin-bottom: 10px;
      padding: 10px 20px;

      &.follow-up-authored {
        float: right !important;
        background-color: #f6f9fb;
      }

      .follow-up-body {
        margin: 10px 0px;
        font-size: 14px;

        .follow-up-files-container {
          display: inline-block;
          float: left;
          width: 100%;
        }
      }

      .follow-up-header {

        .follow-up-author {
          font-size: 15px;
        }

        .follow-up-info {
          float: right;
          font-size: 11px;
          color: #20405c;

          i {
            font-size: 14px;
            margin-right: 0px;
          }
        }
      }

      .follow-up-date {
        span {
          font-size: 11px;
        }
      }

      .follow-up-files-container {
        display: inline-block;
        width: 100%;
        float: left;
        font-size: 12px;
        margin: 10px 0px;

        .fa-paperclip {
          margin-right: 5px;
          font-size: 13px;
        }

        .follow-up-file-link {
          transition: 500ms linear;
          color: #20405c;

          &:hover {
            color: #777;
          }

        }

      }

    }

  }