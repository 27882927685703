.edit_fields_ocult {

  text-align: right;

  a {

    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    @extend .link-gray;

    &:last-child{
      margin-right: 0px !important;
    }

      &.open_field_save {

        color: $green;
        @extend .none;
      }

      &.open_field_cancel {

        color: $error;
        @extend .none;

      }

   }

}


.content-editable {

  &.openTextField {

      .open_field {
        @extend .none;
      }


    .open_field_save,
    .open_field_cancel {

      display: inline-block;

    }
  }
}
