header {

  background: #000;
  height: 70px;
  width: 100%;
  float: left;
  display: inline-block;
  background: url('../img/site/licitacoes/header.jpg') center/cover no-repeat;
  padding: 20px 0;
  color: #fff;

  nav {

      a {

          @extend .link;
          margin-right: 9%;

          &:last-child {

            margin-right: 0px;

          }

      }

  }

  .langs {

    text-align: center;
    padding: 0px;

    a {

      @extend .link;

    }

  }

}


#breadcrumb {

  width: 100%;
  padding: 8px;
  float: left;
  background: #fff;
  border-bottom: 1pt solid #ebebeb;
  color: $gray_1;

  ul {

    padding: 0 10px 0 0;

    li {

      list-style: none;
      display: inline-block;


      &:last-child {

        a, span {

          color: #676a6c;

          &:after {

            display: none;

          }

        }

      }

      span, a {
        color: #999999;
        font-size: 12px;
        margin-right: 0px;
        font-family: $regular;
      }

      a {

        &:after {

          content: '/';
          float: right;
          margin: 0 6px 0 10px;
          height: 18px;
          line-height: 23px;

        }

      }

    }

  }

}
