#categorias_escolhidas {

  font-size: 14px;
  font-family: $semibold;
  color: $gray;
  padding-top: 40px;
  float: left;
  width: 100%;
  padding-left: 20px;

  li {

    padding: 8px;
    background: #fff;


      a {

        color: $gray_1;
        margin-right: 10px;
        display: inline-block;
        vertical-align: top;
        margin-top: 4px;

      }

      span {

        display: inline-block;
        width: 80%;

      }



  }

}
