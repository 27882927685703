//SENDO IMPORTADO EM STAF. REMOVER DE LÁ

.detalhes-fornecedor {
  .shortlist-client {
    b {
      font-size: 14px;
      font-family: $semibold;
    }

    h5 {
      color: #5f5f5f;
    }

    strong {
      font-family: $black;
      color: #5f5f5f;
      font-size: 15px;
    }

    .column4 {
      border-left: 2px solid #ebebeb;
      border-right: 2px solid #ebebeb;
      padding-left: 50px;
    }

    .column2.right {
      line-height: 50px;
    }
  }

  .back-bank {
    font-size: 14px;
    &:hover{
      color: $blue_3 !important;
    }
  }


  .color-label {
    color: $blue !important;
  }

  .requisicao-compra {
    .column4 {
      padding-top: 30px;
      padding-bottom: 30px;

      &:nth-child(even) {
        border-left: 1pt solid $gray_6;
        border-right: 1pt solid $gray_6;
        padding-left: 50px;
        padding-right: 50px;
      }

      label {
        font-size: 20px;
        font-family: $heavy;
        color: $blue !important;
        margin-bottom: 20px;
      }
    }
    margin-bottom: 50px;
    float: left;
    width: 100%;
  }

  b {
    color: $gray_1;
  }

  h5 {
    margin: 0 0 17px;
    color: #20405c;
    font-size: 20px;
    font-family: $black;
  }

  .logo {
    max-width: 220px;
    height: 145px;
    display: inline-block;
    background-image: url('../img/site/logos/placeholder.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }

  .header {
    @extend .full;
    background: $gray;

    strong {
      color: #5f5f5f;
      font-size: 16px;
      text-transform: uppercase;
    }

    .nav {
      //Helpers
      @extend .padding-10-20;
      display: inline-block;

      strong {
        color: $gray_1;
        font-size: 16px;
        font-family: $black;
      }
    }
  }

  #recomendacoes {
    @extend .full;

    li {
      @extend .full;
      border-bottom: 2pt solid #ebebeb;
      padding: 50px 0;
      margin-bottom: -1px;

      span {
        margin-bottom: 20px;
      }

      .avatar {
        font-size: 12px;
        text-align: center;

        figure {
          width: 60px;
          height: 60px;
          display: inline-block;
          margin-bottom: 10px;
          background-image: url('../img/site/avatar.jpg');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }

  .section {
    padding: 30px 20px;
    @extend .full;

    ul {
      li {
        margin-bottom: 4px;
        font-size: 14px;
      }
    }
  }
}
