#licitacoes {
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  color: #909090;

}

.h1,
h1 {
  font-size: 25px;
  font-family: $semibold;
}

.white {
  background: #fff;
}

.profile-percentage {
  padding-bottom: 40px;
}

section {
  padding: 0;
  width: 100%;
  min-height: 100%;
  float: left;
  background: #fff;

  .full {
    padding-top: 40px;

    &:last-child {
      padding-bottom: 40px;
    }
  }

  &.gray {
    background: #f7f7f7;
  }

  h1 {
    margin-bottom: 40px;
  }
}

.form,
form {
  label {
    font-size: 14px;
    color: $label-color;
    font-family: $black;
  }

  input {
    height: $input-height;
    margin-bottom: 26px;
    font-size: 14px;
    color: $gray_1;
    border: 1pt solid #d7d7d7;
    color: #949494;
    box-shadow: inset 0 0 1px 1px #f4f4f4;
  }

  .bt:not(.iti-flag) {
    border-radius: $btn-border-radius;
    height: $input-height;
    // line-height: $input-height;
    font-size: 16px;
    font-family: $bold;
    padding: 0 35px;

    &.default {
      background: $bt-default;

      &:hover {
        background: $bt-disabled;
        color: #fff;
      }
    }

    &.secondary {
      background: $label-color;
      border-radius: $btn-border-radius;

      &.min {
        font-size: 12px;
        line-height: 26px;
        height: auto;
      }
    }
  }
}

li.sine-die-warning fieldset {
  margin-top: 10px;
  padding: 20px 10px !important;
  text-align: center;
}

hr {
  border: none;
  background: none;
  border-bottom: 1pt solid $gray_2;
  width: 100%;
  float: left;
  background: none;
}