.count-requests {
  border-radius: 2px;
  background: #9d9d9d;
  color: #fff;
  font-size: 10px;
  padding: 2px 8px;
  transition: 0.3s linear;
  position: relative;
  top: -10px;

  &.count-requests-inline {
    top: -1px;
  }

  &.count-requests-info {
    background: $blue;
  }

  &.count-requests-danger {
    background: $red;
  }

  &.count-requests-success {
    background: $green;
  }

  &.count-requests-warning {
    background: $yellow;
  }
}

.tabs-container {
  @extend .full;
  padding-bottom: 0 !important;
  padding-top: 25px !important;
  z-index: 0;
  // border-bottom: 2px solid #e7eaec;
  hr {
    border-bottom: 2px solid #f7f7f7 !important;
  }

  label {
    font-size: 16px;
    margin-bottom: 0;
    font-family: $black;
  }

  b {
    i {
      font-size: 26px;
      color: $label-color;
    }
  }

  ul {
    li {
      margin-bottom: 0;
      line-height: 23px;

      i.error {
        font-size: 10px;
        margin-right: 0.4em;
      }

      b {
        // color: $label-color;
        font-family: $black;
      }
    }
  }

  .slick-dots {
    li {
      margin-bottom: 10px;
    }
  }
}

.tabs {
  .five-items {
    li {
      display: inline-block;
      width: 19%;
      margin-left: 2px;
      margin-right: 2px;
    }
  }

  .tabs-step {
    li {
      a {
        background: $gray_2;
        color: #adadad;
        font-family: $heavy;
        font-size: 15px;
        padding: 12px 20px;
        border-radius: 5px;
        float: left;
        width: 100%;

        &.tab-selected {
          background: #f7f7f7;
          color: #9d9d9d;
          /* border-color: #f7f7f7; */
          border: 0px solid #e7eaec !important;

          a,
          i {
            color: #fff;
          }
        }

        i {
          margin-right: 4px;
        }
      }
    }
  }

  .tabs-nav {
    &.four-items {
      li {
        width: 20%;
      }
    }

    &.tabs-dash {
      display: flex;
      z-index: 9999999;
      position: relative;

      li {
        a {
          font-size: 15px;

          &:hover {
            color: #0d61a8;
          }

          span {
            padding: 0 10px;
            line-height: 16px;
            vertical-align: text-bottom;

            &:hover {
              background-color: #0d61a8;
            }
          }
        }
      }
    }

    li {
      display: inline-block;
      margin: 0;

      a {
        border: 2px solid #e7eaec;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-right: none;
        float: left;
        border-bottom: 0;
        font-family: $heavy;
        color: #9d9d9d;
        padding: 18px 0 20px;
        width: 100%;
        border: 2px solid #e7eaec;

        span {
          border-radius: 3px;
          background: #9d9d9d;
          display: inline-block;
          color: #fff;
          font-size: 10px;
          padding: 0 10px;
          line-height: 17px;
          margin-left: 5px;
          // transition: 0.300s linear;

          &:hover {
            background-color: #0d61a8;
          }
        }

        &.tab-selected {
          background: #f7f7f7;
          color: #0e62a8 !important;
          /* border-color: #f7f7f7; */
          border: 2px solid #e7eaec;
          box-shadow: inset 0px 0px 3px #f7f7f7 !important;
          position: relative;

          span {
            background: #f8ac59 !important;
          }

          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 8px;
            bottom: -4px;
            z-index: 9999;
            left: 0;
            background: #f7f8f7;
          }
        }
      }

      &:last-child {
        a {
          border-right: 2px solid #e7eaec;
        }
      }
    }
  }

  .tab-container {
    border-top: 2px solid #e7eaec;
    padding: 40px 0;
    margin-top: -2px !important;

    &.no-background {
      border-top: none;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.tabs-container {
  &.tabs-container-fix-margin {
    .tabs .tab-container {
      /*margin-top: -18px !important;*/
    }
  }

  &.tab-buttons {
    .nav-tabs {
      height: auto;
      border: none;

      li {
        a {
          background: #eeeeee;
          font-family: $heavy;
          margin-right: 10px;
          border: none;
          text-align: center;
          color: #adadad;
          font-size: 14px;
          padding: 14px 30px;
          line-height: 10px;
          height: auto;
          border-radius: 4px;
        }

        &.active {
          a {
            border: none;
            color: #fff;
            background-color: $blue;
            height: inherit;
          }
        }
      }
    }
  }
}

.tab-content {
  float: left;
  width: 100%;
  background: #fff;
  margin-top: -2px;
  padding: 40px 20px;
  border: 2px solid #eee;

  &.tab-content-full {
    border: none;
    background: $gray;
    padding: 50px 0;

    .container12 {
      background: none;
    }
  }

  hr {
    margin: 40px 0 0;
  }
}

.tab {
  thead {
    th {
      padding: 15px 30px;
    }
  }

  tbody {
    tr {
      td {
        padding: 10px 30px;
      }
    }
  }
}

.tab-pane {
  float: left;
}
/* Tabs */
.nav-tabs {
  z-index: 999999;
  position: relative;
}

.nav-tabs > li {
  list-style: none;

  a {
    &.bt {
      border: none !important;
      color: #fff;

      &:hover {
        border: none !important;
      }
    }
  }
}

.nav-tabs > li > a {
  color: $gray_1;
  font-weight: 600;
  height: 60px;
  display: inline-block;
  line-height: 60px;
  margin-right: -2px;
  border: 2px solid #e7eaec;
  padding: 0 20px 0 25px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.tabs-container .panel-body {
  float: left;
  background: #fff;
  border: 2px solid #e7eaec;
  border-radius: 2px;
  padding: 20px;
  position: relative;
}

.tabs-container .nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.tabs-container .tab-pane .panel-body {
  border-top: none;
}

.tabs-container .nav-tabs {
  border-bottom: 1px solid #e7eaec;
  float: left;
  height: 60px;
  width: 100%;
}

.tabs-container .tab-pane .panel-body {
  border-top: none;
}

.tabs-container .tabs-left .tab-pane .panel-body,
.tabs-container .tabs-right .tab-pane .panel-body {
  border-top: 2px solid #e7eaec;
}

.tabs-container .nav-tabs > li a:hover {
  background: #d4d4d4;
  color: #fff;
  border-color: transparent;
}

.tabs-container .tabs-below > .nav-tabs,
.tabs-container .tabs-left > .nav-tabs,
.tabs-container .tabs-right > .nav-tabs {
  border-bottom: 0;
}

.tabs-container .tabs-left .panel-body {
  position: static;
}

.tabs-container .tabs-left > .nav-tabs,
.tabs-container .tabs-right > .nav-tabs {
  width: 20%;
}

.tabs-container .tabs-left .panel-body {
  width: 80%;
  margin-left: 20%;
}

.tabs-container .tabs-right .panel-body {
  width: 80%;
  margin-right: 20%;
}

.tabs-container .pill-content > .pill-pane,
.tabs-container .tab-content > .tab-pane {
  display: none;
  background: #fff;
  padding: 45px 60px;
  border: 2px solid #e7eaec;
  margin-top: -2px;
}

.tabs-container .pill-content > .active,
.tabs-container .tab-content > .active {
  display: block;
}

.tabs-container .tabs-below > .nav-tabs {
  border-top: 1px solid #e7eaec;
}

.tabs-container .tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-container .tabs-below > .nav-tabs > li > a {
  border-radius: 6px;
}

.tabs-container .tabs-below > .nav-tabs > li > a:hover {
  border-top-color: #e7eaec;
  border-bottom-color: transparent;
}

.tabs-container .tabs-left > .nav-tabs > li,
.tabs-container .tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-container .tabs-left > .nav-tabs > li > a,
.tabs-container .tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-container .tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
}

.tabs-container .tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.tabs-container .tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
}

.tabs-container .tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
@media (max-width: 767px) {
  .tabs-container .nav-tabs > li {
    float: none !important;
  }

  .tabs-container .nav-tabs > li.active > a {
    border-bottom: 1px solid #e7eaec !important;
    margin: 0;
  }
}

.close-tab {
  padding-bottom: 0 !important;
  padding-top: 2px !important;
}

.five-items {
  li {
    width: 19.6% !important;
  }

  a {
    font-size: 14px !important;
  }
}

.tabs-nav {
  a {
    &:hover {
      color: $blue !important;
    }

    &.tab-selected {
      color: #fff !important;
    }
  }
}

.eligibilities-declaration {
  .list {
    li {
      margin-bottom: 20px !important;
    }
  }
}
// .show-procurement {
//   hr {
//     margin-top: 10px;
//   }
// }
// #licitacoes {
//   .tab-container {
//     min-height: 600px;
//   }
// }

// .tab-container {
//   .tab-content {
//     li {
//       background-color: #f7f7f7 !important;
//     }
//   }
// }

.li-background-gray {

  li {
    background-color: #f7f7f7 !important;
  }

}

.tab-process-border {
  li {
    a {
    border-right: none !important;
    }

   &:last-child a {
      border-right: 2px solid #e7eaec !important;
    }
  }
 }


.padding-icon-table {
  padding: 0px 10px !important;
}

html {
  .tabs.tab-process {

    li {
      background-color: #ffffff;
    }

    a.disabled {
      cursor: not-allowed !important;
    }

    .tab-selected {
      color: #0d61a8 !important;
      background-color: #f7f7f7 !important;

      &.completed {
        &:before {
          content: '\f058';
          border-radius: 100%;
          float: right;
          margin-top: 0px;
          font-size: 20px;
          font-family: 'FontAwesome';
          line-height: 23px;

        }
      }
      i {
        color: #0d61a8 !important;
      }
    }
  }
}

.licitacao-detail {
  .tabs {
    li {
      background-color: #f7f7f7;
    }

    .tab-selected {
      background-color: #fff !important;

      &:after {

      background: #fff !important;
    }
   }

   a {
     transition-property: none !important;
   }
  }
}
