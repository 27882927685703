footer {
  .logo {
    img {
      width: 25px;
      opacity: .3;
    }
  }

  background: $label-color;

  .copy {

    margin-top: 0px;
    color: #fff;

  }

}
